import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import NoteList from '../components/note-list'
import Pager from '../components/pager'
import Layout from '../layout/layout'

export default function Tag({ pageContext, data }) {
  const { tag } = pageContext

  const { edges, totalCount } = data.allMdx

  const heading = `${totalCount} ${
    totalCount === 1 ? '' : ''
  } ${tag} on ethical sales and growth`

  return (
    <>
      <Helmet>
        {['articles', 'trainings'].includes(tag?.toLowerCase()) ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <Layout title={`Notes tagged with "${tag}"`} type="tag">
        <div className="column is-three-quarters">
          <h1>{heading}</h1>

          <NoteList notes={edges} />

          <Pager context={pageContext} />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    allMdx(
      skip: $skip
      limit: $limit
      sort: { fields: [frontmatter___pubDate], order: [DESC] }
      filter: {
        frontmatter: { tags: { in: [$tag] } }
        fields: { visibility: { eq: "public" } }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            title
            date
            excerpt
          }
          frontmatter {
            tags
            pubDate
            imagePath {
              publicURL
            }
          }
        }
      }
    }
  }
`
